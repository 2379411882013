import { useState, useRef, useEffect } from 'react';
import { LockOpenIcon, LockClosedIcon } from '@heroicons/react/20/solid';
import { Dialog } from '../dialog/dialog';
import { Button } from '../button/button';
import { useLogin } from '../../hooks/useLogin';
import { useUIStore } from '../../app-state/uiStore';

export const LoginDialog = () => {
  const [formEmail, setFormEmail] = useState('');
  const emailInputRef = useRef<HTMLInputElement>(null);
  const isLoginDialogOpen = useUIStore((state) => state.isLoginDialogOpen);
  const closeLoginDialog = useUIStore.getState().closeLoginDialog;

  const { login, loginInProgress, loginError } = useLogin();

  // Focus input when dialog opens
  useEffect(() => {
    if (isLoginDialogOpen && emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, [isLoginDialogOpen]);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    login(formEmail);
  };

  if (!isLoginDialogOpen) return null;

  return (
    <Dialog isOpen={isLoginDialogOpen} onClose={closeLoginDialog}>
      <div className="w-[400px]">
        {loginInProgress ? (
          <div className="flex items-center gap-2 text-sm font-semibold mb-6">
            <LockOpenIcon className="h-5 w-5" />
            <span>Attempting secure connection with the server...</span>
          </div>
        ) : (
          <div className="flex items-center gap-2 text-sm font-semibold mb-6">
            <LockClosedIcon className="h-5 w-5" />
            <span>Connection secured</span>
          </div>
        )}

        <h2 data-testid="signup">Signup</h2>
        <Button variant="CTA">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://9afe9117.sibforms.com/serve/MUIFAKY8flGCkbCt4gNkGTSir5UVLsM87kHz4W5u8JGfF6vU_REJgv3m_4vUXHAYzMGBLta8G3r7rVC-pFmAufNQrenmdyGhoi04sflhOz4lS8H404TzwGT205WG8F6uHcAQELiuswxiKHy_Ci3iiPOAeqDJZvrtmp7kjcV9fT34UxrTUs-Ucn38X1xL5mIufgZY1S5jTAL-eKPO"
          >
            Get Fruitcake
          </a>
        </Button>

        <h2 data-testid="title" className="mt-6">
          Login
        </h2>
        <form className="mb-4" onSubmit={handleSubmit}>
          <label className="block mb-2 text-sm" htmlFor="email">
            Enter your email to receive a login link
          </label>
          <div className="flex gap-3">
            <input
              ref={emailInputRef}
              id="email"
              data-testid="email-input"
              className="text-input w-full max-w-[240px]"
              type="email"
              placeholder="user@email.com"
              onChange={(event) => setFormEmail(event.target.value)}
            />
            <Button
              variant="CTA"
              dataTestId="submit-button"
              disabled={loginInProgress}
            >
              {loginInProgress ? 'SUBMITTING' : 'SUBMIT'}
            </Button>
          </div>
        </form>

        {loginError && (
          <div className="text-red-400">
            We weren't able to log you in with that email address. If you've
            signed up before, please check for typos and try again, or sign up
            using the link above.
          </div>
        )}
      </div>
    </Dialog>
  );
};

import * as ToastPrimitive from '@radix-ui/react-toast';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { NotificationType } from '../../hooks/useNotifications';

interface ToastProps {
  message: JSX.Element;
  onDismiss: () => void;
  type: NotificationType;
}

export const Toast = ({ message, onDismiss, type }: ToastProps) => {
  const handleOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      onDismiss();
    }
  };

  const typeColour =
    type === 'error'
      ? 'bg-red-50/95 border-red-700'
      : type === 'success'
        ? 'bg-green-50/95 border-green-700'
        : 'bg-white/95 border-gray-300';

  return (
    <ToastPrimitive.Root
      duration={Infinity}
      onOpenChange={handleOpenChange}
      className={`${typeColour} relative max-w-md w-full border shadow-md rounded-lg p-4 grid grid-cols-[1fr_auto] items-start gap-2 cursor-pointer`}
    >
      <div className="text-sm font-medium text-gray-900">{message}</div>
      <ToastPrimitive.Action asChild altText="Close">
        <button
          onClick={() => handleOpenChange}
          className="col-start-2 -mt-2 -mr-2 rounded-full bg-gray-100 p-0.5 text-sm font-medium text-gray-700 hover:bg-gray-200 border border-gray-400"
        >
          <XMarkIcon className="h-5 w-5" />
        </button>
      </ToastPrimitive.Action>
    </ToastPrimitive.Root>
  );
};

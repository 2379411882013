import { useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { Payment, usePayments } from '../hooks/usePayments';

interface CharityQuickData {
  name: string;
  url: string;
  link: string;
  amountDonated: BigNumber;
  donationCount: number;
}

export interface CharityDonationData {
  name: string;
  amount: number;
  logoUrl: string;
  percentageOfTotal: string;
}

interface UseImpactDataResult {
  filteredDonations: Payment[];
  stats: {
    totalDonated: BigNumber;
    numberOfDonations: number;
    uniqueCharities: Set<string>;
    charityQuickData: CharityQuickData[];
    largestSingleDonation: BigNumber;
    mostRecentDonationDate: string | null;
    mostRecentDonationAmount: BigNumber | null;
    averageDonationAmount: BigNumber;
    topCharitiesByAmount: string[];
    maxDonationAmount: BigNumber;
    topCharitiesByCount: string[];
    maxDonationCount: number;
    topCharitiesByStreak: string[];
    longestStreak: number;
  };
  charityDonationsData: CharityDonationData[];
  charityDonationCountData: { name: string; count: number }[];
  categoryWordData: { text: string; value: number }[];
}

export const useImpactData = (
  startDate: Date | null,
  endDate: Date | null,
): UseImpactDataResult => {
  const { payments } = usePayments();
  console.log('payments:', payments);

  // Filter donations to include only completed ones within the date range
  const filteredDonations = useMemo(() => {
    const setStartOfDay = (date: Date): Date => {
      const newDate = new Date(date);
      newDate.setHours(0, 0, 0, 0);
      return newDate;
    };

    const setEndOfDay = (date: Date): Date => {
      const newDate = new Date(date);
      newDate.setHours(23, 59, 59, 999);
      return newDate;
    };

    return payments.filter((donation) => {
      if (donation.state !== 'COMPLETED') {
        return false;
      }
      const donationDate = new Date(donation.received_at);
      if (startDate && donationDate < setStartOfDay(startDate)) {
        return false;
      }
      return !(endDate && donationDate > setEndOfDay(endDate));
    });
  }, [payments, startDate, endDate]);

  // Initialize stats
  const stats = useMemo(() => {
    const result: {
      totalDonated: BigNumber;
      numberOfDonations: number;
      uniqueCharities: Set<string>;
      charityQuickData: CharityQuickData[];
      largestSingleDonation: BigNumber;
      mostRecentDonationDate: string | null;
      mostRecentDonationAmount: BigNumber | null;
      topCharitiesByStreak: string[];
      longestStreak: number;
    } = {
      totalDonated: new BigNumber(0),
      numberOfDonations: 0,
      uniqueCharities: new Set<string>(),
      charityQuickData: [],
      largestSingleDonation: new BigNumber(0),
      mostRecentDonationDate: null,
      mostRecentDonationAmount: null,
      topCharitiesByStreak: [],
      longestStreak: 0,
    };

    const charityDataMap: Record<string, CharityQuickData> = {};
    const categoryCounts: Record<string, number> = {}; // Track category counts for word cloud
    const donationStreak: Record<
      string,
      { currentStreak: number; maxStreak: number }
    > = {};

    // Sort donations by date
    const sortedDonations = filteredDonations.sort(
      (a, b) =>
        new Date(a.received_at).getTime() - new Date(b.received_at).getTime(),
    );

    sortedDonations.forEach((donation) => {
      const donationAmount = new BigNumber(donation.amount);
      result.totalDonated = result.totalDonated.plus(donationAmount);
      result.numberOfDonations += 1;

      if (donationAmount.isGreaterThan(result.largestSingleDonation)) {
        result.largestSingleDonation = donationAmount;
      }

      if (
        !result.mostRecentDonationDate ||
        new Date(donation.received_at) > new Date(result.mostRecentDonationDate)
      ) {
        result.mostRecentDonationDate = donation.received_at;
        result.mostRecentDonationAmount = donationAmount;
      }

      // Track charities in the current donation
      const currentDonationCharities: Set<string> = new Set();

      donation.donation_config.forEach((donationConfig) => {
        const charityId = donationConfig.charity.id;
        const charityName = donationConfig.charity.name;
        const charityDonationAmount = donationAmount
          .multipliedBy(donationConfig.percentage)
          .dividedBy(100);

        // Initialize charity data if not already present
        if (!charityDataMap[charityId]) {
          charityDataMap[charityId] = {
            name: charityName,
            url: donationConfig.charity.logo_url,
            link: charityId,
            amountDonated: new BigNumber(0),
            donationCount: 0,
          };
          result.charityQuickData.push(charityDataMap[charityId]);
        }

        charityDataMap[charityId].amountDonated = charityDataMap[
          charityId
        ].amountDonated.plus(charityDonationAmount);
        charityDataMap[charityId].donationCount += 1;

        result.uniqueCharities.add(charityName);
        currentDonationCharities.add(charityId);

        // Track categories for word cloud
        donationConfig.charity.categories.forEach((category: string) => {
          categoryCounts[category] = (categoryCounts[category] || 0) + 1;
        });

        // Calculate streaks based on donations
        if (!donationStreak[charityId]) {
          donationStreak[charityId] = { currentStreak: 1, maxStreak: 1 };
        } else {
          donationStreak[charityId].currentStreak += 1;

          // Update the max streak if the current streak is higher
          if (
            donationStreak[charityId].currentStreak >
            donationStreak[charityId].maxStreak
          ) {
            donationStreak[charityId].maxStreak =
              donationStreak[charityId].currentStreak;
          }
        }
      });

      // Reset streaks for charities not in the current donation
      Object.keys(donationStreak).forEach((charityId) => {
        if (!currentDonationCharities.has(charityId)) {
          donationStreak[charityId].currentStreak = 0;
        }
      });
    });

    // Determine the longest streak and top charities by streak
    result.longestStreak = Math.max(
      ...Object.values(donationStreak).map((ds) => ds.maxStreak),
      0,
    );

    result.topCharitiesByStreak = Object.keys(donationStreak)
      .filter(
        (charityId) =>
          donationStreak[charityId].maxStreak === result.longestStreak,
      )
      .map((charityId) => charityDataMap[charityId].name);

    const averageDonationAmount = result.totalDonated.dividedBy(
      result.numberOfDonations,
    );

    let topCharitiesByAmount: string[] = [];
    let maxDonationAmount = new BigNumber(0);
    let topCharitiesByCount: string[] = [];
    let maxDonationCount = 0;

    result.charityQuickData.forEach((charityData) => {
      // Check for max donation amount
      if (charityData.amountDonated.isGreaterThan(maxDonationAmount)) {
        maxDonationAmount = charityData.amountDonated;
        topCharitiesByAmount = [charityData.name];
      } else if (charityData.amountDonated.isEqualTo(maxDonationAmount)) {
        topCharitiesByAmount.push(charityData.name);
      }

      // Check for max donation count
      if (charityData.donationCount > maxDonationCount) {
        maxDonationCount = charityData.donationCount;
        topCharitiesByCount = [charityData.name];
      } else if (charityData.donationCount === maxDonationCount) {
        topCharitiesByCount.push(charityData.name);
      }
    });

    // Prepare category word cloud data
    const categoryWordData = Object.keys(categoryCounts).map((category) => ({
      text: category,
      value: categoryCounts[category],
    }));

    return {
      ...result,
      averageDonationAmount,
      topCharitiesByAmount,
      maxDonationAmount,
      topCharitiesByCount,
      maxDonationCount,
      categoryWordData,
    };
  }, [filteredDonations]);

  const charityDonationsData = stats.charityQuickData.map((charityData) => ({
    name: charityData.name,
    amount: charityData.amountDonated.toNumber(),
    logoUrl: charityData.url,
    percentageOfTotal: charityData.amountDonated
      .dividedBy(stats.totalDonated)
      .times(100)
      .dp(1)
      .toString(),
  }));

  const charityDonationCountData = stats.charityQuickData.map(
    (charityData) => ({
      name: charityData.name,
      count: charityData.donationCount,
    }),
  );

  return {
    filteredDonations,
    stats,
    charityDonationsData,
    charityDonationCountData,
    categoryWordData: stats.categoryWordData,
  };
};

import { useParams, useNavigate } from 'react-router-dom';
import { CharityAdd } from '../../components/charity-add/charity-add';
import { Card } from '../../components/card/card';
import { SimilarCharities } from '../../components/similar-charities/similar-charities';
import { useState, useEffect } from 'react';
import { routes } from '../routes';
import { useCharities, Charity } from '../../hooks/useCharities';

export const CharityDetails = () => {
  const { charities } = useCharities();
  const { charityId } = useParams<{ charityId: string }>();
  const [charityData, setCharityData] = useState<Charity | null>(null);
  const navigate = useNavigate();

  // Scroll to top whenever the charityId changes (user clicks on a similar charity)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [charityId]);

  useEffect(() => {
    if (charityId) {
      const foundCharity = charities.find((c: Charity) => c.id === charityId);
      setCharityData(foundCharity || null);
    }
  }, [charityId, charities]);

  const handleBackClick = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate(routes.DISCOVER);
    }
  };

  if (!charityData) {
    return <p>Charity not found</p>;
  }

  return (
    <>
      <div className="mb-2">
        <button
          onClick={handleBackClick}
          className="link flex items-center gap-1"
        >
          &#8592; Back
        </button>
      </div>

      <div className="grid lg:grid-cols-2 gap-10">
        <div className="w-full h-32 md:h-52 lg:h-96 max-h-96 rounded-lg overflow-hidden border border-gray-600 lg:my-10">
          <img
            src={charityData.banner_url}
            className="object-cover object-center w-full h-full"
            alt={`${charityData.name} banner`}
          />
        </div>

        <div className="grid grid-rows-[auto,auto,1fr] items-center gap-4 mb-10 lg:mt-10">
          <div className="flex items-center gap-4">
            <div className="rounded-md p-1 bg-white h-16 w-16 lg:h-20 lg:w-20">
              <img
                src={charityData.logo_url}
                alt={`${charityData.name} logo`}
              />
            </div>

            <h1 className="text-2xl font-semibold m-0">{charityData.name}</h1>
          </div>

          <p className="mt-2">{charityData.strapline}</p>

          <div className="w-full rounded overflow-hidden self-end">
            <CharityAdd charity={charityData} textFlexAlign="row" />
          </div>
        </div>
      </div>

      {charityData.categories.length > 0 && (
        <div className="border-t border-gray-300 dark:border-gray-500 py-6">
          <ul className="flex flex-wrap gap-4">
            {charityData.categories.map((category) => (
              <li key={category}>
                <Card defaultPadding={false} shadow={false}>
                  <div className="text-sm p-2 pointer-events-none">
                    {category}
                  </div>
                </Card>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="border-t border-gray-300 dark:border-gray-500 pt-6">
        <h2 className="lowercase italic">In their own words</h2>
        <p className="mt-2">{charityData.description}</p>
      </div>

      <SimilarCharities id={charityId} />
    </>
  );
};

import { useApiQuery } from './useApiQuery';
import { getApiUrl, ApiRoutes } from '../utils/get-api-url';
import { constructGetRequestOptions } from '../utils/construct-get-request-options';
import { handleApiError } from '../utils/handleApiError';
import { useUserProfile } from './useUserProfile';

export interface Charity {
  id: string;
  name: string;
  description: string;
  logo_url: string;
  banner_url: string;
  strapline: string;
  categories: string[];
}

const fetchCharities = async (): Promise<Charity[]> => {
  const requestOptions = constructGetRequestOptions();
  const response = await fetch(
    `${getApiUrl()}${ApiRoutes.CHARITY_LIST}`,
    requestOptions,
  );
  if (!response.ok) {
    await handleApiError(response, 'Failed to fetch charities.');
  }
  const data = await response.json();
  return data.results as Charity[];
};

export const useCharities = () => {
  const { isLoggedIn } = useUserProfile();
  const {
    data: charities = [],
    isLoading: charitiesLoading,
    isError: charitiesError,
    error,
    refetch: refetchCharities,
  } = useApiQuery<Charity[]>(
    ['charities'],
    fetchCharities,
    {
      staleTime: 1000 * 60 * 60 * 12,
      enabled: isLoggedIn,
    },
    'Failed to load charities.',
  );

  return {
    charities,
    charitiesLoading,
    charitiesError,
    error,
    refetchCharities,
  };
};

import { useEffect, ReactElement } from 'react';
import { useUserProfile } from '../../hooks/useUserProfile';
import { useAppInfo } from '../../hooks/useAppInfo';
import { LoadingSpinner } from '../loading-spinner/loading-spinner';

interface ApiConnectorProps {
  children: ReactElement;
}

export const ApiConnector = ({ children }: ApiConnectorProps) => {
  const { userProfile, userProfileLoading, refetchUserProfile } =
    useUserProfile();

  const { appInfoLoading } = useAppInfo();

  useEffect(() => {
    if (!userProfile && !userProfileLoading) {
      refetchUserProfile();
    }
  }, [userProfile, userProfileLoading, refetchUserProfile]);

  if (appInfoLoading) {
    return (
      <div className="h-screen w-screen grid place-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  return children;
};

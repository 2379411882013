import { useDonationConfig } from '../../hooks/useDonationConfig';
import { routes } from '../routes';
import { Link } from 'react-router-dom';
import { Sliders } from '../../components/sliders/sliders';
import { LoadingSpinner } from '../../components/loading-spinner/loading-spinner';

export const DonationConfig = () => {
  const { donationConfig, donationConfigLoading } = useDonationConfig();

  const hasDonations = donationConfig.length > 0;

  if (donationConfigLoading) {
    return <LoadingSpinner />;
  }

  return hasDonations ? (
    <>
      <h1>Your chosen charities</h1>
      <div className="mb-6">
        <Link className="link" to={routes.DISCOVER}>
          Discover more charities
        </Link>
      </div>
      <Sliders donations={donationConfig} />
    </>
  ) : (
    <>
      <h1>Set up your donations</h1>
      <p>
        <span>To get started, </span>
        <Link className="link" to={routes.DISCOVER}>
          search for some charities
        </Link>
      </p>
    </>
  );
};

import React, { ChangeEvent, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { Tooltip } from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUnlock, faXmark } from '@fortawesome/free-solid-svg-icons';
import { dp } from '../../utils/dp';
import { Button } from '../button/button';
import { SliderActions } from '../sliders/sliders';
import { routes } from '../../routes/routes';
import { Link } from 'react-router-dom';
import { Card } from '../card/card';
import { useUIStore } from '../../app-state/uiStore';
import { Charity } from '../../hooks/useCharities';

export interface SliderDataProps {
  id: string;
  percentage: number;
  charity: Charity;
  sliderLocked: boolean;
  sliderLockDisabled: boolean;
}

interface SliderProps {
  id: string;
  maxValue: number;
  totalValue: number;
  sliderData: SliderDataProps;
  handleSliderAction: (
    action: SliderActions,
    sliderData: SliderDataProps,
  ) => void;
  flash?: boolean;
}

const calculateExampleMonetaryValue = (
  percentage: number,
  totalValue?: string,
) => {
  if (!totalValue) {
    return undefined;
  }
  const amount = new BigNumber(totalValue)
    .multipliedBy(percentage)
    .dividedBy(100);

  return amount.toFixed(2);
};

export const Slider = ({
  id,
  maxValue,
  totalValue,
  sliderData,
  handleSliderAction,
  flash = false,
}: SliderProps) => {
  const {
    percentage,
    sliderLocked,
    sliderLockDisabled,
    charity: { logo_url, name: charityName, id: charityId },
  } = sliderData;
  const { openExampleTotalDialog, setShowExampleTotal } = useUIStore.getState();

  const exampleTotal = useUIStore((state) => state.exampleTotal);
  const showExampleTotal = useUIStore((state) => state.showExampleTotal);

  const [sliderExampleMonetaryValue, setSliderExampleMonetaryValue] = useState(
    calculateExampleMonetaryValue(percentage, exampleTotal),
  );

  const [isGlowing, setIsGlowing] = useState<boolean>(false);

  useEffect(() => {
    if (flash) {
      setIsGlowing(true);
    }
  }, [flash]);

  useEffect(() => {
    setSliderExampleMonetaryValue(
      calculateExampleMonetaryValue(percentage, exampleTotal),
    );
  }, [percentage, exampleTotal]);

  const handleShowExampleTotal = () => {
    if (!exampleTotal) {
      openExampleTotalDialog();
    }
    setShowExampleTotal(true);
  };

  const [showRemoveSlider, setShowRemoveSlider] = useState(false);

  const handleKeyOrMouseDown = () => {
    handleSliderAction(SliderActions.START, sliderData);
  };

  const handleKeyOrMouseUp = () => {
    handleSliderAction(SliderActions.END, sliderData);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    handleSliderAction(SliderActions.CHANGE_VALUE, {
      ...sliderData,
      percentage: value > maxValue ? maxValue : value,
    });
    // Remove glow when user interacts with the slider
    if (isGlowing) {
      setIsGlowing(false);
    }
  };

  return (
    <div className={`rounded-lg ${isGlowing ? 'glow-flash' : ''}`}>
      <Card defaultPadding={false}>
        <div className="grid grid-cols-[auto_1fr]">
          <Link to={`${routes.DISCOVER}/${charityId}`}>
            <div className="row-span-3 h-full border-r border-gray-200 dark:border-gray-700 p-2 bg-white">
              <img
                className="w-[60px] h-[60px] sm:w-[80px] sm:h-[80px] md:w-[120px] md:h-[120px] object-contain rounded-md"
                src={logo_url}
                alt={charityName}
              />
            </div>
          </Link>

          <div className="grid grid-rows-[auto_1fr] py-2 pl-4 pr-2">
            <div className="mb-2 flex items-start justify-between min-h-[34px] relative">
              <Link to={`${routes.DISCOVER}/${charityId}`}>
                <h3 className="text-lg font-semibold m-0">{charityName}</h3>
              </Link>

              <div className="min-w-[155px] text-right">
                {showRemoveSlider ? (
                  <div className="flex items-center gap-2 absolute top-0 right-0">
                    <Button
                      variant="default"
                      onClick={() => {
                        setShowRemoveSlider(false);
                        handleSliderAction(SliderActions.REMOVE, sliderData);
                      }}
                      dataTestId="remove-slider-btn"
                    >
                      <div className="flex items-center h-4">Remove</div>
                    </Button>
                    <Button
                      variant="default"
                      onClick={() => setShowRemoveSlider(false)}
                      dataTestId="cancel-remove-slider-btn"
                    >
                      <div className="flex items-center h-4">Keep</div>
                    </Button>
                  </div>
                ) : (
                  <Button
                    variant="default"
                    onClick={() => setShowRemoveSlider(true)}
                    dataTestId="show-remove-confirm-btn"
                  >
                    <div className="sr-only">Remove slider</div>
                    <div className="grid place-items-center w-4 h-4 leading-none">
                      <FontAwesomeIcon icon={faXmark} />
                    </div>
                  </Button>
                )}
              </div>
            </div>

            <div className="grid grid-cols-[1fr,auto] items-center gap-x-4 md:gap-x-2">
              <label className="hidden" htmlFor={id}>
                {charityName}
              </label>
              <input
                id={id}
                type="range"
                min={0}
                max={totalValue}
                value={percentage}
                disabled={sliderLocked}
                onChange={handleChange}
                onMouseUp={handleKeyOrMouseUp}
                onMouseDown={handleKeyOrMouseDown}
                onKeyUp={handleKeyOrMouseUp}
                onKeyDown={handleKeyOrMouseDown}
                className="w-full rounded-full h-10 bg-gray-300 cursor-pointer"
              />

              <div
                className="pl-2"
                data-tooltip-id="slider-lock-tooltip"
                data-tooltip-content={
                  (sliderLockDisabled &&
                    'Lock disabled - at least two sliders need to remain unlocked to allow adjustment') ||
                  (sliderLocked &&
                    'Unlock slider - this allows its value to change with the other sliders') ||
                  'Lock slider - this value will not change when other sliders are moved'
                }
              >
                <Button
                  dataTestId="toggle-locked-btn"
                  variant="clean"
                  disabled={sliderLockDisabled}
                  onClick={() =>
                    sliderLocked
                      ? handleSliderAction(SliderActions.UNLOCK, sliderData)
                      : handleSliderAction(SliderActions.LOCK, sliderData)
                  }
                >
                  {sliderLocked ? (
                    <FontAwesomeIcon icon={faLock} />
                  ) : (
                    <FontAwesomeIcon icon={faUnlock} />
                  )}
                </Button>

                <Tooltip
                  id="slider-lock-tooltip"
                  place="bottom"
                  className="hidden md:block"
                  delayShow={800}
                />
              </div>

              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-end col-span-2 pt-2 pr-3">
                <div>
                  Donating <strong>{dp(percentage, 1)}%</strong>
                </div>

                {!showExampleTotal && (
                  <button
                    onClick={() => handleShowExampleTotal()}
                    className="text-sm underline"
                  >
                    see £ example
                  </button>
                )}

                {showExampleTotal && sliderExampleMonetaryValue && (
                  <div className="text-sm">
                    <span>e.g. £{sliderExampleMonetaryValue}</span>
                    {exampleTotal && (
                      <span>
                        {' '}
                        of a{' '}
                        <button
                          onClick={() => openExampleTotalDialog()}
                          className="underline"
                        >
                          £{exampleTotal}
                        </button>{' '}
                        donation
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export const handleApiError = async (
  response: Response,
  customMessage?: string,
): Promise<never> => {
  const status = response.status;
  const statusText = response.statusText;

  let errorMessage = `${customMessage && customMessage + ' '} Request failed with status ${status}: ${statusText}`;

  try {
    const errorData = await response.json();
    if (errorData?.message) {
      errorMessage += ` - ${errorData.message}`;
    }
  } catch {
    const errorText = await response.text();
    if (errorText) {
      errorMessage += ` - ${errorText}`;
    }
  }

  throw new Error(errorMessage);
};

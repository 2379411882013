import { useCallback } from 'react';
import { Donation, useDonationConfig } from '../hooks/useDonationConfig';
import { useCsrfToken } from '../hooks/useCsrfToken';

export const useCharityAdd = () => {
  const {
    donationConfig,
    donationConfigLoading,
    saveDonationConfig,
    donationConfigSaving,
  } = useDonationConfig();

  const { csrfTokenLoading } = useCsrfToken();

  const handleCharityAdd = useCallback(
    async (charityId: string, percentage: number) => {
      if (donationConfigLoading || donationConfigSaving) return;

      const donationsToPost = [
        ...donationConfig,
        {
          charity: {
            id: charityId,
          },
          percentage,
        } as Donation,
      ];

      if (!csrfTokenLoading) {
        saveDonationConfig(donationsToPost);
      }
    },
    [
      donationConfigLoading,
      donationConfigSaving,
      donationConfig,
      saveDonationConfig,
      csrfTokenLoading,
    ],
  );

  return { handleCharityAdd };
};

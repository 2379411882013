import { useApiQuery } from './useApiQuery';
import { getApiUrl, ApiRoutes } from '../utils/get-api-url';
import { constructGetRequestOptions } from '../utils/construct-get-request-options';
import { Charity } from './useCharities';
import { handleApiError } from '../utils/handleApiError';

const fetchSimilarCharities = async (charityId: string): Promise<Charity[]> => {
  if (!charityId) {
    throw new Error('Charity ID is required to fetch similar charities');
  }
  const requestOptions = constructGetRequestOptions();
  const response = await fetch(
    `${getApiUrl()}${ApiRoutes.getSimilarCharitiesUrl(charityId)}?limit=4&randomness=1`,
    requestOptions,
  );
  if (!response.ok) {
    await handleApiError(response, 'Failed to fetch similar charities.');
  }
  const data = await response.json();
  return data.results as Charity[];
};

export const useSimilarCharities = (charityId: string) => {
  const {
    data: similarCharities = [],
    isLoading: similarCharitiesLoading,
    isError: similarCharitiesError,
  } = useApiQuery<Charity[]>(
    ['similarCharities', charityId],
    () => fetchSimilarCharities(charityId),
    {
      enabled: !!charityId,
      staleTime: 0,
    },
    'Failed to fetch similar charities.',
  );

  return {
    similarCharities,
    similarCharitiesLoading,
    similarCharitiesError,
  };
};

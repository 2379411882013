import { useApiMutation } from './useApiMutation';
import { ApiRoutes, getApiUrl } from '../utils/get-api-url';
import { constructPostRequestOptions } from '../utils/construct-post-request-options';
import { useCsrfToken } from './useCsrfToken';
import { useUIStore } from '../app-state/uiStore';
import { routes } from '../routes/routes';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '../app-state/userStore';

const loginUser = async (email: string, csrfToken: string): Promise<string> => {
  const url = `${getApiUrl()}${ApiRoutes.AUTH_LOGIN_REGISTER}`;
  const requestBody = { email };

  const response = await fetch(
    url,
    constructPostRequestOptions(csrfToken, requestBody),
  );

  if (!response.ok) {
    throw new Error('Failed to send login request.');
  }

  const data = await response.json();
  return data.message;
};

export const useLogin = () => {
  const { csrfToken } = useCsrfToken();
  const navigate = useNavigate();
  const closeLoginDialog = useUIStore.getState().closeLoginDialog;
  const { setEmailLinkSent } = useUserStore.getState();

  const {
    mutate: login,
    isPending: loginInProgress,
    isSuccess: loginSuccess,
    isError: loginError,
  } = useApiMutation<string, string>(
    (email) => loginUser(email, csrfToken || ''),
    {
      onSuccess: () => {
        // Close the dialog, notify the user, and redirect
        closeLoginDialog();
        setEmailLinkSent(true);
        navigate(routes.CHECK_EMAIL, { replace: true });
      },
    },
    'Failed to log in.',
  );

  return { login, loginInProgress, loginSuccess, loginError };
};

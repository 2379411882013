import { useQuery, UseQueryOptions, QueryKey } from '@tanstack/react-query';
import { useUIStore } from '../app-state/uiStore';
import { useEffect, useRef } from 'react';

export const useApiQuery = <TData>(
  queryKey: QueryKey,
  queryFn: () => Promise<TData>,
  options?: Omit<UseQueryOptions<TData, Error>, 'queryKey' | 'queryFn'>,
  errorMessage?: string,
) => {
  const displayError = useUIStore.getState().displayError;
  const clearError = useUIStore.getState().clearError;

  // Track the error ID for this specific query
  const errorIdRef = useRef<string | null>(null);

  const { data, isLoading, isError, isSuccess, isFetching, error, refetch } =
    useQuery<TData>({
      queryKey,
      queryFn,
      ...options,
    });

  useEffect(() => {
    if (isError && error instanceof Error) {
      errorIdRef.current = displayError(
        `${errorMessage || error.message} Please refresh to try again. If it persists, please contact support.`,
      );
    }

    if ((isSuccess || isFetching) && errorIdRef.current) {
      clearError(errorIdRef.current);
      errorIdRef.current = null;
    }
  }, [
    isError,
    isSuccess,
    isFetching,
    error,
    displayError,
    clearError,
    errorMessage,
  ]);

  return { data, isLoading, isError, error, refetch };
};

import { useApiQuery } from './useApiQuery';
import { getApiUrl, ApiRoutes } from '../utils/get-api-url';
import { constructGetRequestOptions } from '../utils/construct-get-request-options';
import { handleApiError } from '../utils/handleApiError';
import { useUserProfile } from './useUserProfile';
import { ProcessedDonation } from './useDonationConfig';

export interface Payment {
  transaction_id: string;
  received_at: string;
  amount: string;
  state: 'IN PROGRESS' | 'COMPLETED' | 'HALTED' | 'REFUNDED';
  donation_config: ProcessedDonation[];
}

const fetchPayments = async (): Promise<Payment[]> => {
  const requestOptions = constructGetRequestOptions();
  const response = await fetch(
    `${getApiUrl()}${ApiRoutes.PAYMENTS_LIST}`,
    requestOptions,
  );
  if (!response.ok) {
    await handleApiError(response, 'Failed to fetch payments.');
  }
  const data = await response.json();
  return data.payments as Payment[];
};

export const usePayments = () => {
  const { isLoggedIn, userProfile } = useUserProfile();

  const {
    data: payments = [],
    isLoading: paymentsLoading,
    isError: paymentsError,
    refetch: refetchPayments,
  } = useApiQuery<Payment[]>(
    ['payments'],
    fetchPayments,
    {
      staleTime: 1000 * 60,
      enabled: isLoggedIn && !!userProfile?.payment_reference,
    },
    'Failed to load your payments data.',
  );

  return {
    payments,
    paymentsLoading,
    paymentsError,
    refetchPayments,
  };
};

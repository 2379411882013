import { useUIStore } from '../../app-state/uiStore';
import { useAppInfo } from '../../hooks/useAppInfo';
import { faqs } from '../faqs/faqs';
import { Disclosure } from '@headlessui/react';
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline';
import {
  CheckCircleIcon,
  ChevronDoubleRightIcon,
  MagnifyingGlassCircleIcon,
  ClockIcon,
  ChartPieIcon,
  LockClosedIcon,
  Cog8ToothIcon,
  RocketLaunchIcon,
  HeartIcon,
} from '@heroicons/react/20/solid';
import configScreenshot from '../../assets/config-screenshot.jpg';
import discoverScreenshot from '../../assets/discover-screenshot.jpg';
import { Button } from '../../components/button/button';
import { LoadingSpinner } from '../../components/loading-spinner/loading-spinner';

const primaryFeatures = [
  {
    name: 'Discover Charities',
    description:
      'From local to global, emergency aid to long term change, quickly discover the charities that inspire you to make a difference.',
    icon: MagnifyingGlassCircleIcon,
  },
  {
    name: 'Flexible Donation Scheduling',
    description:
      'Donate in a way that suits you - one-time, weekly, monthly, or even daily! Fruitcake gives you the flexibility to support your causes as regularly or irregularly as you wish, without locking you into rigid schedules.',
    icon: ClockIcon,
  },
  {
    name: 'One Payment, Multiple Impacts',
    description:
      'Make a single payment and we’ll distribute it among your chosen charities based on your settings. Adjustment is really easy. Set your donation total and how you want it distributed, and we do the rest.',
    icon: ChartPieIcon,
  },
];
const secondaryFeatures = [
  {
    name: 'Easy Setup, Effortless Management',
    description:
      'Get started in moments – no paperwork, no lengthy calls. With Fruitcake, you can set up and manage all your donating in one intuitive dashboard. Make adjustments on the fly, without the administrative headaches.',
    icon: CheckCircleIcon,
  },
  {
    name: "Support As Many Charities As You'd Like",
    description:
      "There are so many great causes out there – and with Fruitcake, you can support as many as you'd like, all in one place. No more juggling multiple donations in different places. Fruitcake consolidates everything for you.",
    icon: HeartIcon,
  },
  {
    name: 'Privacy First',
    description:
      'Donate anonymously and support your favorite causes without the need to share your personal information.',
    icon: LockClosedIcon,
  },
  {
    name: 'Total Donation Control',
    description:
      'You’re always in control. With Fruitcake, you can easily view, adjust, or pause your donations at any time. No need to reach out to charities or banks—everything is managed right from your dashboard.',
    icon: Cog8ToothIcon,
  },
  {
    name: 'Donating in Moments',
    description:
      'No one enjoys lengthy phone calls or feeling hassled by fundraisers on the street. Choose your charities and donate on your own terms, without the pressure or hassle.',
    icon: ChevronDoubleRightIcon,
  },
  {
    name: 'All Your Giving in One Place',
    description:
      'Fruitcake is your one-stop solution for managing all your charitable contributions. View your donation history, edit your preferences, and track the impact of your giving – all from a single platform.',
    icon: RocketLaunchIcon,
  },
];

export const Landing = () => {
  const { appInfo, appInfoLoading } = useAppInfo();
  const { openLoginDialog } = useUIStore.getState();

  if (appInfoLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div data-testid="landing-page">
      <main>
        <div className="mx-auto max-w-8xl pt-12 pb-24 sm:px-6 lg:px-8">
          <div className="relative isolate overflow-hidden bg-gray-900 dark:bg-gray-100 px-6 py-20 sm:rounded-3xl sm:px-10 sm:py-24 xl:px-24">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-center lg:gap-y-0">
              <div className="lg:row-start-2 lg:max-w-md">
                <h2 className="text-3xl font-bold tracking-tight text-white dark:text-black sm:text-4xl">
                  Find your causes, donate flexibly and effortlessly
                </h2>
                <p className="mt-6 text-lg leading-8 text-gray-300 dark:text-gray-800">
                  Discover the charities that matter to you. Assign each charity
                  a slice of your total, then make a donation through Fruitcake
                  to support them all. Adjust your slices whenever you like and
                  donate as often as you choose. It's that simple. Welcome to
                  Fruitcake.
                </p>
              </div>
              <img
                src={discoverScreenshot}
                alt="Product screenshot"
                className="relative -z-20 min-w-full max-w-xl rounded-xl shadow-xl ring-1 ring-white/10 lg:row-span-4 lg:w-[64rem] lg:max-w-none"
                width={600}
                height={600}
              />
              <div className="max-w-xl lg:row-start-3 lg:mt-10 lg:max-w-md lg:border-t lg:border-white/10 lg:pt-10">
                <dl className="max-w-xl space-y-8 text-base leading-7 text-gray-300 dark:text-gray-900 lg:max-w-none">
                  {primaryFeatures.map((feature) => (
                    <div key={feature.name} className="relative">
                      <dt className="ml-9 mr-2 inline-block font-semibold text-white dark:text-black">
                        <feature.icon
                          className="absolute left-1 top-1 h-5 w-5 text-indigo-500"
                          aria-hidden="true"
                        />
                        {feature.name}
                      </dt>
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                </dl>

                <div className="mt-10">
                  <Button onClick={openLoginDialog} variant="CTA">
                    Get started
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="relative isolate mt-32 sm:mt-40">
          <div className="mx-auto max-w-8xl px-6 pb-24 lg:flex lg:items-center lg:gap-x-10 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
              <h1 className="max-w-2xl text-5xl font-bold text-gray-900">
                Welcome to Fruitcake - Simplify Your Giving
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-300">
                We believe that by making donating to charity as effortless as
                possible, the world benefits. Whether you're exploring
                charitable giving for the first time or you're a frequent donor,
                our app makes it straightforward and flexible for you to
                contribute to the causes you care about most.
              </p>
              <div className="mt-10">
                <Button onClick={openLoginDialog} variant="CTA">
                  Sign up or Login
                </Button>
              </div>
            </div>
            <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
              <svg
                viewBox="0 0 366 729"
                role="img"
                className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl"
              >
                <title>App screenshot</title>
                <defs>
                  <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                    <rect width={316} height={684} rx={36} />
                  </clipPath>
                </defs>
                <path
                  fill="#4B5563"
                  d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
                />
                <path
                  fill="#343E4E"
                  d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
                />
                <foreignObject
                  width={316}
                  height={684}
                  transform="translate(24 24)"
                  clipPath="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
                >
                  <img src={configScreenshot} alt="" />
                </foreignObject>
              </svg>
            </div>
          </div>
        </div>

        <div className="mx-auto mt-32 max-w-8xl pt-6 pb-24 sm:mt-40 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
              Control and Transparency in Your Donations
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-300">
              With Fruitcake, you have complete control over your donations.
              Manage all your charitable giving in one place, with the ease and
              flexibility you need.
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
              {secondaryFeatures.map((feature) => (
                <div key={feature.name} className="flex flex-col">
                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900 dark:text-gray-100">
                    <feature.icon
                      className="h-5 w-5 flex-none text-indigo-600"
                      aria-hidden="true"
                    />
                    {feature.name}
                  </dt>
                  <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600 dark:text-gray-400">
                    <p className="flex-auto">{feature.description}</p>
                  </dd>
                </div>
              ))}
            </dl>
          </div>

          <div className="mt-10 text-center">
            <Button onClick={openLoginDialog} variant="CTA">
              Let's go!
            </Button>
          </div>
        </div>

        <div className="mx-auto max-w-8xl py-24 sm:mt-24 lg:px-8 border-y border-gray-300">
          <div className="mx-auto max-w-4xl divide-y divide-gray-900/10 dark:divide-white/10">
            <h2 className="text-2xl font-bold leading-10 tracking-tight">
              Frequently asked questions
            </h2>
            <dl className="mt-10 space-y-6 divide-y divide-gray-900/10 dark:divide-white/10">
              {faqs(appInfo?.fruitcake_percentage).map(
                (faq) =>
                  faq && (
                    <Disclosure as="div" key={faq.question} className="pt-6">
                      {({ open }) => (
                        <>
                          <dt>
                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900 dark:text-gray-100">
                              <span className="text-base font-semibold leading-7">
                                {faq.question}
                              </span>
                              <span className="ml-6 flex h-7 items-center">
                                {open ? (
                                  <MinusSmallIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <PlusSmallIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            </Disclosure.Button>
                          </dt>
                          <Disclosure.Panel as="dd" className="mt-2 pr-12">
                            <p className="text-base leading-7 text-gray-600 dark:text-gray-400">
                              {faq.answer}
                            </p>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ),
              )}
            </dl>
          </div>
        </div>

        <div className="mx-auto mt-24 max-w-8xl pt-6 pb-24 lg:px-8 text-center">
          <h2 className="text-3xl font-bold tracking-tight text-black dark:text-white sm:text-4xl pb-4">
            Ready to begin?
          </h2>
          <Button onClick={openLoginDialog} variant="CTA">
            Get started
          </Button>
        </div>
      </main>
    </div>
  );
};

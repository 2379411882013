import BigNumber from 'bignumber.js';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUserProfile } from '../../hooks/useUserProfile';
import { Donation, useDonationConfig } from '../../hooks/useDonationConfig';
import { routes } from '../../routes/routes';
import { Button } from '../button/button';
import { Dialog } from '../dialog/dialog';
import { dp } from '../../utils/dp';
import { useUIStore } from '../../app-state/uiStore';
import { AppInfo, useAppInfo } from '../../hooks/useAppInfo';
import { LoadingSpinner } from '../loading-spinner/loading-spinner';


const BackButton = ({ onClickAction }: { onClickAction: () => void }) => (
  <button
    onClick={onClickAction}
    className="absolute top-3 left-3 rounded-full bg-gray-100 p-2 text-gray-700 hover:bg-gray-200"
  >
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 1 L3 6 L9 11"
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </button>
);

const CurrentDonationConfigTable = ({
  currentDonationConfig,
}: {
  currentDonationConfig: Donation[];
}) => {
  return (
    <>
      <h3 className="mb-6 text-center">Your current charity settings:</h3>

      <div className="max-w-xl mb-6 mx-auto overflow-hidden border border-gray-200 rounded-lg shadow-sm">
        <table className="min-w-full divide-y divide-gray-200 bg-white dark:bg-gray-700/20">
          <thead className="text-left">
            <tr>
              <th className="px-4 py-2 text-sm font-medium">Charity</th>
              <th className="px-4 py-2 text-sm font-medium">Percentage</th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-gray-700/20 divide-y divide-gray-200">
            {currentDonationConfig.map((donation) => (
              <tr key={donation.charity.id}>
                <td className="px-4 py-2 whitespace-nowrap text-sm">
                  <div className="flex items-center gap-2">
                    <img
                      height={24}
                      width={24}
                      src={donation.charity.logo_url}
                      alt={`${donation.charity.name} logo`}
                      className="inline-block bg-white"
                    />
                    <span>{donation.charity.name}</span>
                  </div>
                </td>
                <td className="px-4 py-2 whitespace-nowrap text-sm">
                  {dp(donation.percentage, 1)}%
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

const PaymentsDisabledInfo = () => (
  <div>
    <p>
      We've now stopped accepting donations for this alpha version
      of Fruitcake. Please don't send us any money!
    </p>
    <p>
      We are currently building the next version of Fruitcake
      which will have an in-app payment interface along with many other
      improvements.
    </p>
  </div>
);

const FeeInfo = ({
  appInfo,
  exampleFruitcakeFee,
  exampleDonationAmount,
}: {
  appInfo: AppInfo | undefined;
  exampleFruitcakeFee: string | 0 | undefined;
  exampleDonationAmount: number;
}) => (
  <div>
    {appInfo?.fruitcake_percentage && exampleFruitcakeFee && (
      <p>
        <span className="font-semibold">Please note:</span> we take a{' '}
        {appInfo?.fruitcake_percentage}% fee on each donation. This helps cover
        our costs and keeps Fruitcake running smoothly. For example, if you
        donate £{exampleDonationAmount}, our fee is just
        {' ' + exampleFruitcakeFee}p.
      </p>
    )}
  </div>
);

export const DonateDialog = () => {
  const navigate = useNavigate();
  const { appInfo, appInfoLoading } = useAppInfo();
  const { donationConfig } = useDonationConfig();
  const isDonateDialogOpen = useUIStore((state) => state.isDonateDialogOpen);
  const { userProfile, userProfileLoading } = useUserProfile();
  const paymentReference = userProfile?.payment_reference;

  const { closeDonateDialog } = useUIStore.getState();

  const [view, setView] = useState('intro');
  const exampleDonationAmount = 10;
  const exampleFruitcakeFee =
    appInfo?.fruitcake_percentage &&
    BigNumber(exampleDonationAmount)
      .times(appInfo.fruitcake_percentage)
      .toFixed(0);

  const handleAdjustCharities = () => {
    closeDonateDialog();
    navigate(routes.DONATION_CONFIG);
  };

  const dialogContent = () => {
    if (appInfoLoading) {
      return <LoadingSpinner />;
    }
    if (donationConfig.length > 0) {
      switch (view) {
        case 'intro':
          return (
            <div data-testid="donate-dialog-intro">
              <CurrentDonationConfigTable
                currentDonationConfig={donationConfig}
              />

              <div className="mt-4 text-center">
                <Button onClick={() => handleAdjustCharities()}>
                  Adjust my charities
                </Button>
              </div>

              <p className="mt-10">
                You can make donations either as a one-off or recurring, and
                make them as often as you wish. When you donate, we check your
                current donation settings and split your donation accordingly.
              </p>
              <div className="flex justify-center gap-4 my-10">
                <Button variant="CTA" onClick={() => setView('one-off')}>
                  Make a one-off donation
                </Button>
                <Button variant="CTA" onClick={() => setView('regular')}>
                  Set up a regular donation
                </Button>
              </div>
              <FeeInfo
                appInfo={appInfo}
                exampleFruitcakeFee={exampleFruitcakeFee}
                exampleDonationAmount={exampleDonationAmount}
              />
            </div>
          );
        case 'one-off':
          return (
            <div data-testid="donate-dialog-one-off">
              <BackButton onClickAction={() => setView('intro')} />
              <PaymentsDisabledInfo/>
            </div>
          );
        case 'regular':
          return (
            <div data-testid="donate-dialog-regular">
              <BackButton onClickAction={() => setView('intro')} />
              <PaymentsDisabledInfo/>
            </div>
          );
        default:
          return null;
      }
    } else {
      return (
        <div className="mb-6">
          Ready to donate? Start by discovering and selecting your favourite
          charities here:
          <Link className="link" to={routes.DISCOVER}>
            Discover Charities
          </Link>
        </div>
      );
    }
  };

  return (
    <Dialog
      isOpen={isDonateDialogOpen}
      onClose={() => {
        setView('intro');
        closeDonateDialog();
      }}
      title={view === 'intro' ? 'How to Donate' : 'Donations disabled'}
    >
      <div className="max-w-3xl">{dialogContent()}</div>
    </Dialog>
  );
};

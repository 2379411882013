import { SliderDataProps } from '../components/slider/slider';

import { Donation } from '../hooks/useDonationConfig';

// This function is used to create the required slider data from donations data.
export const createSlidersData = (donations: Donation[]): SliderDataProps[] => {
  if (!donations.length) {
    return [];
  }

  return donations
    .sort((a, b) => b.percentage - a.percentage)
    .map((donation) => ({
      id: `slider-${donation.charity.id}`,
      percentage: donation.percentage,
      sliderLocked: donations.length === 1,
      sliderLockDisabled: donations.length <= 2,
      charity: donation.charity,
    }));
};

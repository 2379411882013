import * as ToastPrimitive from '@radix-ui/react-toast';
import {
  useNotifications,
  Notification,
  NotificationType,
} from '../../hooks/useNotifications';
import { Toast } from '../toast/toast';

export const NotificationsManager = () => {
  const { unseenNotifications, notificationsLoading, markNotificationAsSeen } =
    useNotifications();

  const getMessageAndType = (
    notification: Notification,
  ): { message: JSX.Element; type: NotificationType } => {
    switch (notification.category) {
      case 'PAYMENT_RECEIVED':
        return {
          message: (
            <>
              <div className="mb-2">
                {new Date(notification.created_at).toLocaleString()}
              </div>
              🎉 Thank you for your donation of{' '}
              {notification.data.amount_display}! We’re now allocating it to
              your chosen charities.
            </>
          ),
          type: 'success',
        };
      case 'NO_CHARITIES_CONFIGURED_FOR_PAYMENT':
        return {
          message: (
            <>
              <div className="mb-2">
                {new Date(notification.created_at).toLocaleString()}
              </div>
              ⚠️ Oops! We received your donation of{' '}
              {notification.data.amount_display}, but no charities are set up to
              receive it. Don’t worry, we’ll refund you shortly.
            </>
          ),
          type: 'error',
        };
      case 'PAYMENT_ALLOCATED':
        const allocationDetails =
          notification.data.charity_name_percentage_pairs
            .map((pair) => `${pair.charity_name}: ${pair.percentage}%`)
            .join(', ');
        return {
          message: (
            <>
              <div className="mb-2">
                {new Date(notification.created_at).toLocaleString()}
              </div>
              💸 Your donation of {notification.data.amount_display} has been
              allocated! Here’s where it’s heading: {allocationDetails}
            </>
          ),
          type: 'success',
        };
      default:
        return { message: <>Unknown notification category</>, type: 'info' };
    }
  };

  if (notificationsLoading) return <p>Loading notifications...</p>;

  return (
    <>
      {unseenNotifications.map((notification) => {
        const { message, type } = getMessageAndType(notification);

        return (
          <Toast
            key={notification.id}
            message={message}
            onDismiss={() => markNotificationAsSeen(notification.id)}
            type={type}
          />
        );
      })}

      <ToastPrimitive.Viewport className="fixed top-12 right-5 p-6 flex flex-col gap-4" />
    </>
  );
};

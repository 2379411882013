import { useApiQuery } from './useApiQuery';
import { getApiUrl, ApiRoutes } from '../utils/get-api-url';
import { constructGetRequestOptions } from '../utils/construct-get-request-options';
import { handleApiError } from '../utils/handleApiError';

interface UserProfile {
  email: string;
  payment_reference: string;
}

const fetchUserProfile = async (): Promise<UserProfile | null> => {
  const requestOptions = constructGetRequestOptions();
  const response = await fetch(
    `${getApiUrl()}${ApiRoutes.USER_PROFILE}`,
    requestOptions,
  );

  if (response.status === 401) {
    // User is not logged in; return null without throwing an error
    return null;
  }

  if (!response.ok) {
    await handleApiError(response, 'Failed to fetch user profile data.');
  }

  const data = await response.json();
  return data as UserProfile;
};

export const useUserProfile = () => {
  const {
    data: userProfile,
    isLoading: userProfileLoading,
    isError: userProfileError,
    refetch: refetchUserProfile,
  } = useApiQuery<UserProfile | null>(
    ['userProfile'],
    fetchUserProfile,
    {
      staleTime: Infinity,
    },
    'Failed to fetch your user profile data.',
  );

  const isLoggedIn = userProfile !== undefined && userProfile !== null;

  return {
    userProfile,
    userProfileLoading,
    userProfileError,
    refetchUserProfile,
    isLoggedIn,
  };
};

import { useEffect } from 'react';
import { useApiQuery } from './useApiQuery';
import { getApiUrl, ApiRoutes } from '../utils/get-api-url';
import { constructGetRequestOptions } from '../utils/construct-get-request-options';
import { handleApiError } from '../utils/handleApiError';
import { useUserProfile } from './useUserProfile';

const fetchCsrfToken = async (): Promise<string> => {
  const response = await fetch(
    `${getApiUrl()}${ApiRoutes.CSRF_TOKEN}`,
    constructGetRequestOptions(),
  );

  if (!response.ok) {
    await handleApiError(response, 'Failed to fetch CSRF token.');
  }

  const data = await response.json();
  return data.token;
};

export const useCsrfToken = () => {
  const { userProfile } = useUserProfile();

  const {
    data: csrfToken,
    isLoading: csrfTokenLoading,
    isError: csrfTokenError,
    refetch: refetchCsrfToken,
  } = useApiQuery<string>(
    ['csrfToken'],
    fetchCsrfToken,
    {
      staleTime: Infinity,
    },
    'Failed to fetch CSRF token. Please refresh and try again.',
  );

  // Automatically refetch CSRF token when user email changes (i.e. when user logs in or out)
  useEffect(() => {
    refetchCsrfToken();
  }, [userProfile?.email, refetchCsrfToken]);

  return { csrfToken, csrfTokenLoading, csrfTokenError, refetchCsrfToken };
};

import { useApiMutation } from './useApiMutation';
import { getApiUrl, ApiRoutes } from '../utils/get-api-url';
import { constructPostRequestOptions } from '../utils/construct-post-request-options';
import { useCsrfToken } from './useCsrfToken';
import { routes } from '../routes/routes';

const performLogout = async (csrfToken: string) => {
  const url = `${getApiUrl()}${ApiRoutes.AUTH_LOGOUT}`;
  const requestOptions = constructPostRequestOptions(csrfToken, {});
  const response = await fetch(url, requestOptions);

  if (!response.ok) {
    throw new Error('Logout failed. Please try again.');
  }

  return response.json();
};

export const useLogout = () => {
  const { csrfToken } = useCsrfToken();

  const {
    mutate: logout,
    isPending: logoutPending,
    isError: logoutError,
    isSuccess: logoutSuccess,
  } = useApiMutation<void, void>(
    () => performLogout(csrfToken || ''),
    {
      onSuccess: () => {
        // Redirect and force refresh on successful logout
        window.location.href = routes.HOME;
      },
    },
    'Logout failed. Please try again.',
  );

  return { logout, logoutPending, logoutError, logoutSuccess };
};

import { useApiQuery } from './useApiQuery';
import { getApiUrl, ApiRoutes } from '../utils/get-api-url';
import { constructGetRequestOptions } from '../utils/construct-get-request-options';
import { handleApiError } from '../utils/handleApiError';

export interface AppInfo {
  fruitcake_percentage: number;
}

const fetchAppInfo = async (): Promise<AppInfo> => {
  const requestOptions = constructGetRequestOptions();
  const response = await fetch(
    `${getApiUrl()}${ApiRoutes.APP_INFO}`,
    requestOptions,
  );
  if (!response.ok) {
    await handleApiError(response, 'Failed to fetch app info.');
  }
  const data = await response.json();
  return data as AppInfo;
};

export const useAppInfo = () => {
  const {
    data: appInfo,
    isLoading: appInfoLoading,
    isError: appInfoError,
  } = useApiQuery<AppInfo>(
    ['appInfo'],
    fetchAppInfo,
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
    'Failed to load app data.',
  );

  return { appInfo, appInfoLoading, appInfoError };
};

import { useAppInfo } from '../../hooks/useAppInfo';
import { LoadingSpinner } from '../../components/loading-spinner/loading-spinner';

export const faqs = (fruitcakeFee?: number) => [
  {
    question: 'How does Fruitcake work?',
    answer:
      'When you make a donation through Fruitcake, we check your chosen charities in your Fruitcake account and distribute the payment according to your specified percentages. We then pool together donations from different donors to the same charity and send them in one go, minimising the number of transactions. ' +
      'This allows you to donate to as many or as few charities as you like, whenever you like, with no lock-in, no hassle and complete flexibility.',
  },
  {
    question: 'How do I donate through Fruitcake?',
    answer:
      'Simply select the charities you would like to donate to and set your percentage for each charity, then make a bank transfer (either one-off or repeating) to our donor account, and we’ll do the rest.',
  },
  {
    question:
      'How does Fruitcake simplify the process of donating to charities?',
    answer:
      'Fruitcake streamlines your donation experience by allowing you to select from a wide range of charities, set up flexible donation schedules, and give to all your selected charities through one single payment. This eliminates the need for filling out lengthy forms or setting up multiple direct debits.',
  },
  {
    question: 'Can I donate to multiple charities through Fruitcake?',
    answer:
      'Yes, Fruitcake lets you divide your donation among as many charities as you like, all from one payment.',
  },
  {
    question: 'Is it possible to donate anonymously using Fruitcake?',
    answer:
      'Absolutely. Your donations are completely anonymous, ensuring your personal information remains confidential while supporting the causes you care about. ' +
      'We plan to soon add the option to share your details with the charities if you want to.',
  },
  {
    question: 'How does Fruitcake handle transaction fees?',
    answer:
      'By consolidating your donations into a single payment, Fruitcake reduces the number of transactions, thereby reducing any possible transaction fees. This means more of your money goes directly to the charities.',
  },
  fruitcakeFee && {
    question: 'Does Fruitcake take a fee?',
    answer:
      `We charge a ${fruitcakeFee}% fee on each donation to keep Fruitcake running smoothly. This helps us cover our costs and make sure your donations are processed securely and efficiently. This is similar to what would be taken from a normal card payment, but as we use bank transfers we avoid those fees. ` +
      'Some card payments also have a minimum fee (e.g. £0.20 per transaction), so if you were to donate to multiple charities by card, the amount lost in fees could be a lot higher. ' +
      'Our aim is to be a highly efficient way to donate, while also giving you great convenience, flexibility, anonymity and control.',
  },
  {
    question:
      'Can I change how much I donate or how often after I’ve set it up?',
    answer:
      'Yes, we currently only take donations via bank transfer, which means that you are in complete control of your payments. ' +
      'Simply make a one-off payment or set up a recurring payment from your bank account, and change it whenever you like.',
  },
  {
    question:
      'Can I change which charities I donate to after I’ve made a payment?',
    answer:
      'Whenever we receive a payment containing your unique donor reference, we distribute that payment according to the charities and percentages you’ve set in your Fruitcake account at the time. ' +
      'Once you’ve received confirmation from us that we’ve processed that payment, you can then change your chosen charities or percentages ready for the next payment.',
  },
  {
    question: 'Is Fruitcake suitable for one-time donations?',
    answer:
      'Yes, while Fruitcake is designed for easy setup of recurring donations, it also accommodates one-time donations, giving you the flexibility to support causes as you choose.',
  },
];

export const FAQs = () => {
  const { appInfo, appInfoLoading } = useAppInfo();

  if (appInfoLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div data-testid="faqs">
      <div className="divide-y divide-gray-900/10 dark:divide-white/10">
        <div>
          <h1>Frequently asked questions</h1>
          <p className="mt-6 max-w-2xl text-base leading-7 text-gray-600 dark:text-gray-400">
            Have a different question and can’t find the answer you’re looking
            for? Reach out to our support team by{' '}
            <a
              href="mailto:fruitcakeapp@gmail.com"
              className="font-semibold text-indigo-600 hover:text-indigo-500"
            >
              sending us an email
            </a>{' '}
            and we’ll get back to you as soon as we can.
          </p>
        </div>

        <dl className="mt-10 space-y-8 divide-y divide-gray-900/10 dark:divide-white/10">
          {faqs(appInfo?.fruitcake_percentage).map(
            (faq, index) =>
              faq && (
                <div
                  key={index}
                  className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8"
                >
                  <dt className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-100 lg:col-span-5">
                    {faq.question}
                  </dt>
                  <dd className="mt-4 lg:col-span-7 lg:mt-0">
                    <p className="text-base leading-7 text-gray-600 dark:text-gray-400">
                      {faq.answer}
                    </p>
                  </dd>
                </div>
              ),
          )}
        </dl>
      </div>
    </div>
  );
};

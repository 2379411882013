import { useSimilarCharities } from '../../hooks/useSimilarCharities';
import { CharityList } from '../charity-list/charity-list';
import { Card } from '../card/card';

interface SimilarCharitiesProps {
  id?: string;
}

export const SimilarCharities = ({ id }: SimilarCharitiesProps) => {
  const { similarCharities, similarCharitiesLoading, similarCharitiesError } =
    useSimilarCharities(id || '');

  if (!id) {
    console.log('No charity ID provided for similar charities');
    return null;
  }

  if (similarCharitiesLoading || similarCharitiesError) {
    return null;
  }

  return (
    <div className="mt-36">
      <Card>
        <h2 className="mt-2 mb-6">
          Other charities you might be interested in
        </h2>
        <CharityList
          charities={similarCharities}
          showAddCharity={false}
          smallLogos={true}
        />
      </Card>
    </div>
  );
};

import { create } from 'zustand';

interface DonationsState {
  userHasClearedDonationConfig: boolean;
}

interface DonationsActions {
  setUserHasClearedDonationConfig: (cleared: boolean) => void;
}

export const useDonationsStore = create<DonationsState & DonationsActions>(
  (set) => ({
    userHasClearedDonationConfig: false,

    setUserHasClearedDonationConfig: (cleared) => {
      set(() => ({ userHasClearedDonationConfig: cleared }));
    },
  }),
);

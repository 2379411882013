import { useDonationConfig } from '../../hooks/useDonationConfig';
import { useUserProfile } from '../../hooks/useUserProfile';
import { usePayments } from '../../hooks/usePayments';
import { Link } from 'react-router-dom';
import { routes } from '../../routes/routes';
import { useUIStore } from '../../app-state/uiStore';

export const setupStepsContent = {
  step1: {
    heading: 'Welcome to Fruitcake!',
  },
  step2: {
    heading: 'Next step',
  },
  step3: {
    heading: 'You’re ready to donate',
  },
};

export const SetupSteps = () => {
  const { userProfile } = useUserProfile();
  const { email } = userProfile || {};
  const { donationConfig } = useDonationConfig();
  const { payments, paymentsLoading } = usePayments();

  const { openDonateDialog } = useUIStore.getState();

  if (!email || paymentsLoading || payments.length > 0) {
    return null;
  }

  const step1Content = (
    <div data-testid="setup-step-1">
      Get started by{' '}
      <span>
        <Link className="link" to={routes.DISCOVER}>
          discovering charities
        </Link>
      </span>
    </div>
  );

  const step2Content = (
    <div data-testid="setup-step-2">
      <Link className="link" to={routes.DONATION_CONFIG}>
        Adjust your donation percentages
      </Link>
    </div>
  );

  const step3Content = (
    <div data-testid="setup-step-3">
      <span>
        <Link className="link" to={routes.DISCOVER}>
          Add more charities
        </Link>
      </span>
      <span> or </span>
      <button onClick={openDonateDialog} className="link">
        make a donation
      </button>
    </div>
  );

  // step 1: no donations set up
  const isStep1 = donationConfig.length === 0;
  // step 2: more than one donation, but a single donation is set to 100%
  const isStep2 =
    donationConfig.length > 1 &&
    donationConfig.some((donation) => donation.percentage === 100);
  // step 3: more than one donation, none are 100%, or only one donation
  const isStep3 =
    (donationConfig.length > 1 &&
      !donationConfig.some((donation) => donation.percentage === 100)) ||
    donationConfig.length === 1;

  return (
    <div
      data-testid="intro-steps"
      className="grid justify-center items-center bg-lime-100 mb-8 px-6 py-3 border border-slate-200 rounded"
    >
      <div className="w-full flex flex-wrap items-center gap-x-2 gap-y-2 leading-6 text-gray-900">
        <strong className="font-semibold">
          {(isStep1 && setupStepsContent.step1.heading) ||
            (isStep2 && setupStepsContent.step2.heading) ||
            (isStep3 && setupStepsContent.step3.heading)}
        </strong>
        <svg
          viewBox="0 0 2 2"
          className="mx-2 inline h-0.5 w-0.5 fill-current"
          aria-hidden="true"
        >
          <circle cx={1} cy={1} r={1} />
        </svg>
        {(isStep1 && step1Content) ||
          (isStep2 && step2Content) ||
          (isStep3 && step3Content)}
      </div>
    </div>
  );
};

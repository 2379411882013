import { create } from 'zustand';
import { nanoid } from 'nanoid';

interface ErrorMessage {
  id: string;
  message: string;
}

interface UIState {
  errors: ErrorMessage[];
  isDonateDialogOpen: boolean;
  isLoginDialogOpen: boolean;
  exampleTotal?: string;
  showExampleTotal: boolean;
  isExampleMonetaryDialogOpen: boolean;
  theme: 'light' | 'dark';
  currentDonationToHighlight?: string;
}

interface UIActions {
  displayError: (message: string, timeout?: number) => string;
  clearError: (id: string) => void;
  clearAllErrors: () => void;
  openDonateDialog: () => void;
  closeDonateDialog: () => void;
  openLoginDialog: () => void;
  closeLoginDialog: () => void;
  setExampleTotal: (total: string) => void;
  setShowExampleTotal: (show: boolean) => void;
  openExampleTotalDialog: () => void;
  closeExampleTotalDialog: () => void;
  setTheme: (theme?: 'light' | 'dark') => void;
  toggleTheme: () => void;
  setCurrentDonationToHighlight: (charityId: string) => void;
  clearCurrentDonationToHighlight: () => void;
}

const isBrowser = typeof window !== 'undefined';

const getInitialTheme = (): 'light' | 'dark' => {
  if (!isBrowser) return 'light';

  const storedTheme = localStorage.getItem('theme');
  if (storedTheme === 'light' || storedTheme === 'dark') {
    return storedTheme;
  }

  return window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light';
};

export const useUIStore = create<UIState & UIActions>((set, get) => ({
  errors: [],
  isDonateDialogOpen: false,
  isLoginDialogOpen: false,
  exampleTotal: undefined,
  showExampleTotal: false,
  isExampleMonetaryDialogOpen: false,
  theme: getInitialTheme(),
  currentDonationToHighlight: undefined,

  displayError: (message, timeout) => {
    const existingError = get().errors.find(
      (error) => error.message === message,
    );

    if (existingError) {
      return existingError.id;
    }

    // Create a new error if one doesn't exist
    const id = nanoid();
    set((state) => ({
      ...state,
      errors: [...state.errors, { id, message, count: 1 }],
    }));

    if (timeout) {
      setTimeout(() => {
        const errorToClear = get().errors.find(
          (error) => error.message === message,
        );
        if (errorToClear) {
          get().clearError(errorToClear.id);
        }
      }, timeout);
    }

    return id;
  },
  clearError: (id) => {
    set((state) => ({
      ...state,
      errors: state.errors.filter((error) => error.id !== id),
    }));
  },
  clearAllErrors: () => {
    set((state) => ({
      ...state,
      errors: [],
    }));
  },
  setExampleTotal: (total) => {
    set((state) => ({
      ...state,
      exampleTotal: total,
    }));
  },
  setShowExampleTotal: (show) => {
    set((state) => ({
      ...state,
      showExampleTotal: show,
    }));
  },
  openDonateDialog: () => {
    set((state) => ({
      ...state,
      isDonateDialogOpen: true,
    }));
  },
  closeDonateDialog: () => {
    set((state) => ({
      ...state,
      isDonateDialogOpen: false,
    }));
  },
  openLoginDialog: () => {
    set((state) => ({
      ...state,
      isLoginDialogOpen: true,
    }));
  },
  closeLoginDialog: () => {
    set((state) => ({
      ...state,
      isLoginDialogOpen: false,
    }));
  },
  openExampleTotalDialog: () => {
    set((state) => ({
      ...state,
      isExampleMonetaryDialogOpen: true,
    }));
  },
  closeExampleTotalDialog: () => {
    set((state) => ({
      ...state,
      isExampleMonetaryDialogOpen: false,
    }));
  },
  setTheme: (theme) => {
    if (theme) {
      localStorage.setItem('theme', theme);
      if (theme === 'dark') {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
      set((state) => ({
        ...state,
        theme,
      }));
    }
  },
  toggleTheme: () => {
    const currentTheme = get().theme;
    const newTheme = currentTheme === 'light' ? 'dark' : 'light';
    get().setTheme(newTheme);
  },
  setCurrentDonationToHighlight: (charityId) => {
    set((state) => ({
      ...state,
      currentDonationToHighlight: charityId,
    }));
  },
  clearCurrentDonationToHighlight: () => {
    set((state) => ({
      ...state,
      currentDonationToHighlight: undefined,
    }));
  },
}));

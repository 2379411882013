import { useApiQuery } from './useApiQuery';
import { getApiUrl, ApiRoutes } from '../utils/get-api-url';
import { constructGetRequestOptions } from '../utils/construct-get-request-options';
import { Charity } from './useCharities';
import { handleApiError } from '../utils/handleApiError';

const fetchRecommendedCharities = async (): Promise<Charity[]> => {
  const requestOptions = constructGetRequestOptions();
  const response = await fetch(
    `${getApiUrl()}${ApiRoutes.RECOMMENDED_CHARITIES}?limit=4&randomness=1`,
    requestOptions,
  );
  if (!response.ok) {
    await handleApiError(response, 'Failed to recommended charities.');
  }
  const data = await response.json();
  return data.results as Charity[];
};

export const useRecommendedCharities = () => {
  const {
    data: recommendedCharities = [],
    isLoading: recommendedCharitiesLoading,
    isError: recommendedCharitiesError,
    refetch: refetchRecommendedCharities,
  } = useApiQuery<Charity[]>(
    ['recommendedCharities'],
    fetchRecommendedCharities,
    {
      staleTime: 1000 * 60,
    },
    'Failed to fetch recommended charities.',
  );

  return {
    recommendedCharities,
    recommendedCharitiesLoading,
    recommendedCharitiesError,
    refetchRecommendedCharities,
  };
};

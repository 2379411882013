import { Button } from '../button/button';
import { useLogout } from '../../hooks/useLogout';

export const Logout = () => {
  const { logout, logoutPending } = useLogout();

  return (
    <Button
      dataTestId="logout-btn"
      variant="CTA"
      onClick={() => logout()}
      disabled={logoutPending}
    >
      {logoutPending ? 'Logging out...' : 'Logout'}
    </Button>
  );
};

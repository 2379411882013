import { useEffect, useState, useRef, ChangeEvent } from 'react';
import { useUIStore } from '../../app-state/uiStore';
import { usePayments } from '../../hooks/usePayments';
import { Dialog } from '../dialog/dialog';
import { Button } from '../button/button';

import { Payment } from '../../hooks/usePayments';

const getLatestCompletedPaymentAmount = (payments: Payment[]) =>
  payments.find((payment) => payment.state === 'COMPLETED')?.amount;

const defaultExampleTotal = '10.00';

export const ExampleMonetaryDialog = () => {
  const { payments } = usePayments();
  const isExampleMonetaryDialogOpen = useUIStore(
    (state) => state.isExampleMonetaryDialogOpen,
  );
  const exampleTotal = useUIStore((state) => state.exampleTotal);
  const { closeExampleTotalDialog, setExampleTotal } = useUIStore.getState();

  const [latestAllocatedPaymentAmount, setLatestAllocatedPaymentAmount] =
    useState(getLatestCompletedPaymentAmount(payments));

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    // Checks and sets the latest allocated payment amount if the donations list has updated
    if (
      getLatestCompletedPaymentAmount(payments) !== latestAllocatedPaymentAmount
    ) {
      setLatestAllocatedPaymentAmount(
        getLatestCompletedPaymentAmount(payments),
      );
    }
  }, [payments, latestAllocatedPaymentAmount]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    // Allow only numbers and one decimal point
    if (/^\d*\.?\d*$/.test(value)) {
      setExampleTotal(value);
    }
  };

  const handleBlur = () => {
    const total = exampleTotal || '0.00'; // Provide a default value if exampleTotal is undefined
    if (total === '' || isNaN(parseFloat(total))) {
      setExampleTotal('0.00');
    } else {
      setExampleTotal(parseFloat(total).toFixed(2));
    }
  };

  const handleClose = () => {
    if (!exampleTotal) {
      if (latestAllocatedPaymentAmount) {
        setExampleTotal(latestAllocatedPaymentAmount);
      } else {
        setExampleTotal(defaultExampleTotal);
      }
    }
    closeExampleTotalDialog();
  };

  return (
    <Dialog
      isOpen={isExampleMonetaryDialogOpen}
      onClose={() => {
        if (!exampleTotal) {
          if (latestAllocatedPaymentAmount) {
            setExampleTotal(latestAllocatedPaymentAmount);
          } else {
            setExampleTotal(defaultExampleTotal);
          }
        }
        closeExampleTotalDialog();
      }}
      title="Set your example total"
    >
      <div className="max-w-3xl">
        <div className="flex items-center justify-center mb-6 -mt-4">
          <label htmlFor="amount" className="mr-1">
            £
          </label>
          <input
            ref={inputRef}
            type="text"
            inputMode="decimal"
            id="amount"
            value={
              exampleTotal
                ? exampleTotal
                : latestAllocatedPaymentAmount
                  ? latestAllocatedPaymentAmount
                  : defaultExampleTotal
            }
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Enter amount"
            className="text-input w-20 text-lg"
          />
        </div>

        <div className="text-sm mt-4">
          <span>
            Fruitcake works with percentages, not fixed values. This means you
            can donate a different amount any time without having to update your
            donations list. However, if you'd like to see an example of how your
            donations would be split, you can set an example total here.
          </span>
          {!exampleTotal && latestAllocatedPaymentAmount && (
            <span>
              {' '}
              Your example total is currently set to your last donation.
            </span>
          )}
        </div>

        <div className="text-sm mt-4">
          Note: example values are approximate and do not take into account
          Fruitcake's fee of 1.5%.
        </div>

        <div className="text-right">
          <Button variant="CTA" onClick={handleClose}>
            Done
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

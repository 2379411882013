import { create } from 'zustand';

interface UserState {
  emailLinkSent: boolean;
}

interface UserActions {
  setEmailLinkSent: (emailLinkSent: boolean) => void;
}

const initialUserState: UserState = {
  emailLinkSent: false,
};

export const useUserStore = create<UserState & UserActions>((set, get) => ({
  ...initialUserState,

  setEmailLinkSent: (emailLinkSent) => {
    set((state) => ({
      ...state,
      emailLinkSent,
    }));
  },
}));

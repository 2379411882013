import { useMemo } from 'react';
import { useApiQuery } from './useApiQuery';
import { useApiMutation } from './useApiMutation';
import { useQueryClient } from '@tanstack/react-query';
import { useUserProfile } from './useUserProfile';
import { useCsrfToken } from './useCsrfToken';
import { getApiUrl, ApiRoutes } from '../utils/get-api-url';
import { constructGetRequestOptions } from '../utils/construct-get-request-options';
import { constructPostRequestOptions } from '../utils/construct-post-request-options';
import { handleApiError } from '../utils/handleApiError';

export interface NotificationPaymentReceivedData {
  transaction_id: string;
  amount_display: string;
}

export interface NotificationNoCharitiesConfiguredForPaymentData {
  transaction_id: string;
  amount_display: string;
}

export interface CharityNamePercentagePair {
  charity_name: string;
  percentage: number;
}

export interface NotificationPaymentAllocatedData {
  transaction_id: string;
  amount_display: string;
  charity_name_percentage_pairs: CharityNamePercentagePair[];
}

export interface NotificationBase {
  id: string;
  is_seen: boolean;
  seen_at: string | null;
  email_sent: boolean;
  created_at: string;
}

export interface PaymentReceivedNotification extends NotificationBase {
  category: 'PAYMENT_RECEIVED';
  data: NotificationPaymentReceivedData;
}

export interface NoCharitiesConfiguredForPaymentNotification
  extends NotificationBase {
  category: 'NO_CHARITIES_CONFIGURED_FOR_PAYMENT';
  data: NotificationNoCharitiesConfiguredForPaymentData;
}

export interface PaymentAllocatedNotification extends NotificationBase {
  category: 'PAYMENT_ALLOCATED';
  data: NotificationPaymentAllocatedData;
}

export type Notification =
  | PaymentReceivedNotification
  | NoCharitiesConfiguredForPaymentNotification
  | PaymentAllocatedNotification;

export type NotificationType = 'error' | 'success' | 'info';

export const useNotifications = () => {
  const { csrfToken } = useCsrfToken();
  const queryClient = useQueryClient();
  const { isLoggedIn } = useUserProfile();

  const fetchNotifications = async (): Promise<Notification[]> => {
    const requestOptions = constructGetRequestOptions();
    const response = await fetch(
      `${getApiUrl()}${ApiRoutes.NOTIFICATIONS}`,
      requestOptions,
    );

    if (!response.ok) {
      await handleApiError(response, 'Failed to fetch notifications.');
    }
    const data = await response.json();
    return data.results as Notification[];
  };

  const {
    data: notifications = [],
    isLoading: notificationsLoading,
    isError: notificationsError,
  } = useApiQuery<Notification[]>(
    ['notifications'],
    fetchNotifications,
    {
      refetchInterval: 1000 * 60 * 5,
      enabled: isLoggedIn,
    },
    'Failed to load your notifications.',
  );

  const {
    mutate: markNotificationAsSeen,
    isPending: markAsSeenPending,
    isError: markAsSeenError,
    isSuccess: markAsSeenSuccess,
  } = useApiMutation<string, void>(
    async (id: string) => {
      const requestBody = { id };
      const requestOptions = constructPostRequestOptions(
        csrfToken,
        requestBody,
      );

      const response = await fetch(
        `${getApiUrl()}${ApiRoutes.NOTIFICATIONS_MARK_SEEN}`,
        requestOptions,
      );

      if (!response.ok) {
        throw new Error('Failed to mark notification as seen');
      }

      return response.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['notifications'] });
      },
    },
    'Failed to mark notification as seen.',
  );

  const unseenNotifications = useMemo(
    () => notifications.filter((notification) => !notification.is_seen),
    [notifications],
  );

  return {
    notifications,
    unseenNotifications,
    notificationsLoading,
    notificationsError,
    markNotificationAsSeen,
    markAsSeenPending,
    markAsSeenError,
    markAsSeenSuccess,
  };
};

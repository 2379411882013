import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useUIStore } from '../app-state/uiStore';
import { useEffect, useRef } from 'react';

export const useApiMutation = <TVariables, TData>(
  mutationFn: (variables: TVariables) => Promise<TData>,
  options?: Omit<UseMutationOptions<TData, Error, TVariables>, 'mutationFn'>,
  errorMessage?: string,
) => {
  const displayError = useUIStore.getState().displayError;
  const clearError = useUIStore.getState().clearError;

  // Track the error ID for this specific mutation
  const errorIdRef = useRef<string | null>(null);

  const { mutate, data, isPending, isError, isSuccess, isIdle, error } =
    useMutation<TData, Error, TVariables>({
      mutationFn,
      ...options,
    });

  useEffect(() => {
    if (isError && error instanceof Error) {
      errorIdRef.current = displayError(
        `${errorMessage || error.message} Please try again. If the issue persists, contact support.`,
      );
    }

    if ((isSuccess || isIdle) && errorIdRef.current) {
      clearError(errorIdRef.current);
      errorIdRef.current = null;
    }
  }, [
    isError,
    isSuccess,
    isIdle,
    error,
    displayError,
    clearError,
    errorMessage,
  ]);

  return { mutate, data, isPending, isError, isSuccess, error };
};

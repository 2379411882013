import { useQueryClient } from '@tanstack/react-query';
import { useApiQuery } from './useApiQuery';
import { useApiMutation } from './useApiMutation';
import { useDonationsStore } from '../app-state/donationsStore';
import { useCsrfToken } from './useCsrfToken';
import { ApiRoutes, getApiUrl } from '../utils/get-api-url';
import { constructGetRequestOptions } from '../utils/construct-get-request-options';
import { constructPostRequestOptions } from '../utils/construct-post-request-options';
import { handleApiError } from '../utils/handleApiError';
import { useUserProfile } from './useUserProfile';
import { Charity } from './useCharities';

export interface Donation {
  percentage: number;
  charity: Charity;
}

export interface ProcessedDonation extends Donation {
  amount: string;
}

const fetchDonationConfig = async (): Promise<Donation[]> => {
  const requestOptions = constructGetRequestOptions();
  const response = await fetch(
    `${getApiUrl()}${ApiRoutes.USER_DONATION_CONFIG}`,
    requestOptions,
  );
  if (!response.ok) {
    await handleApiError(response, 'Failed to fetch donation config.');
  }
  const data = await response.json();
  return data.charities as Donation[];
};

const sendDonationConfig = async (
  donations: Donation[],
  csrfToken: string,
): Promise<{ response: unknown; donations: Donation[] }> => {
  const url = `${getApiUrl()}${ApiRoutes.USER_DONATION_CONFIG}`;
  const requestBody = { charities: donations };
  const requestOptions = constructPostRequestOptions(csrfToken, requestBody);

  const response = await fetch(url, requestOptions);

  if (!response.ok) {
    await handleApiError(response, 'Failed to save donation configuration.');
  }

  const jsonResponse = await response.json();

  return { response: jsonResponse, donations };
};

export const useDonationConfig = () => {
  const queryClient = useQueryClient();
  const { isLoggedIn } = useUserProfile();
  const { csrfToken } = useCsrfToken();
  const { setUserHasClearedDonationConfig } = useDonationsStore();

  const {
    data: donationConfig = [],
    isLoading: donationConfigLoading,
    isError: donationConfigError,
    refetch: refetchDonationConfig,
  } = useApiQuery<Donation[]>(
    ['donationConfig'],
    fetchDonationConfig,
    {
      staleTime: 1000 * 60 * 60,
      enabled: isLoggedIn,
    },
    'Failed to load your donations configuration.',
  );

  const {
    mutate: saveDonationConfig,
    isPending: donationConfigSaving,
    isError: donationConfigSavingError,
    isSuccess: donationConfigSaved,
  } = useApiMutation<Donation[], { donations: Donation[]; response: unknown }>(
    (donations) => sendDonationConfig(donations, csrfToken || ''),
    {
      onSuccess: ({ donations }) => {
        // Invalidate the query (this will refetch the donation config)
        queryClient.invalidateQueries({ queryKey: ['donationConfig'] });
        setUserHasClearedDonationConfig(donations.length === 0);
      },
    },
    'Failed to save your donation configuration.',
  );

  return {
    donationConfig,
    donationConfigLoading,
    donationConfigError,
    refetchDonationConfig,
    saveDonationConfig,
    donationConfigSaving,
    donationConfigSavingError,
    donationConfigSaved,
  };
};
